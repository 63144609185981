import React from "react";
import { Card } from "react-bootstrap";
import { BlogArticle } from "../models";

import BlogPreview from "./blogPreview";

interface Props {
  articles: BlogArticle[];
}

const BlogList = (props: Props) => {
  const { articles } = props;
  return (
    <Card className="blog-list">
      <Card.Body>
        <Card.Title>Blog</Card.Title>
        <div>
          {articles.map(a => (
            <BlogPreview key={a.id} article={a} />
          ))}
        </div>
      </Card.Body>
    </Card>
  );
};

export default BlogList;
