import { Col, Container, Row } from "react-bootstrap";
import { PageProps, graphql } from "gatsby";
import React from "react";

import BlogList from "../components/blogList";
import Presentation from "../components/presentation";
import SEO from "../components/seo";
import { AllDirectusMettanBlog } from "../models";

const Index: React.FC<PageProps<AllDirectusMettanBlog>> = ({ data }) => {
  return (
    <>
      <SEO title="Home" />
      <Container fluid style={{ maxWidth: 1400 }}>
        <Row>
          <Col xl={2} md={3} sm={12}>
            <Presentation />
          </Col>
          <Col xl={10} md={9} sm={12}>
            <BlogList articles={data.allDirectusMettanBlog.nodes} />
          </Col>
        </Row>
      </Container>
    </>
  );
};

export const query = graphql`
  {
    allDirectusMettanBlog(sort: { fields: created_on, order: DESC }) {
      nodes {
        id
        url
        created_on
        title
        content
      }
    }
  }
`;

export default Index;
