import React from "react";
import { Card, Col, Container, Row } from "react-bootstrap";

import github from "../images/github.png";
import linkedin from "../images/linkedin.png";
import twitter from "../images/twitter.png";
import mail from "../images/mail.png";

const Presentation = () => (
  <Card className="presentation">
    <Card.Img
      variant="top"
      src="https://cms.mettan.dev/cms-digitalp/assets/qitn8maokwgc4cck"
      alt="profil-picture"
      width="auto"
      height="auto"
    />
    <Card.Body>
      <Card.Title style={{ textAlign: "center" }}>Yoan Mettan</Card.Title>
      <Card.Body style={{ padding: "0.5rem" }}>
        Welcome to my personal programming blog. I hope you will find some usefull knowledges here
        <hr />
        <Container className="social" fluid>
          <Row>
            <Col>
              <a href="https://github.com/mettany" >
                <img src={`${github}`} alt="github" className="social-icon" />
              </a>
            </Col>
            <Col>
              <a href="https://www.linkedin.com/in/yoan-mettan-21a90a128/" >
                <img src={`${linkedin}`} alt="linkedin" className="social-icon" />
              </a>
            </Col>
            <Col>
              <a href="https://twitter.com/YMettan" >
                <img src={`${twitter}`} alt="twitter" className="social-icon" />
              </a>
            </Col>
            <Col>
              <a href="mailto:y.mettan@gmail.com">
                <img src={`${mail}`} alt="mail" className="social-icon" />
              </a>
            </Col>
          </Row>
        </Container>
      </Card.Body>
    </Card.Body>
  </Card>
);

export default Presentation;
